var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('div',[_c('v-row',[_c('v-col',{staticClass:"pr-md-10",attrs:{"cols":"12","sm":"6"}},[_c('password-field',{attrs:{"label":_vm.$t('labels.passwordCurrent'),"attrs":{
            label: _vm.$t('labels.passwordCurrent'),
            rules: [_vm.rule.required],
          }},model:{value:(_vm.formData.old_password),callback:function ($$v) {_vm.$set(_vm.formData, "old_password", $$v)},expression:"formData.old_password"}})],1),_c('v-col',{staticClass:"pl-md-10",attrs:{"cols":"12","sm":"6"}},[_c('password-field',{attrs:{"attrs":{
            label: _vm.$t('labels.passwordNew'),
            rules: [_vm.rule.required, _vm.rule.password],
          }},model:{value:(_vm.formData.new_password),callback:function ($$v) {_vm.$set(_vm.formData, "new_password", $$v)},expression:"formData.new_password"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pl-md-10",attrs:{"cols":"12","sm":"6","offset-sm":"6"}},[_c('password-field',{attrs:{"attrs":{
            label: _vm.$t('labels.passwordConfirm'),
            rules: [
              _vm.rule.required,
              _vm.rule.same(_vm.$t('labels.passwordNew'), _vm.formData.new_password) ],
          }},model:{value:(_vm.formData.password_confirmation),callback:function ($$v) {_vm.$set(_vm.formData, "password_confirmation", $$v)},expression:"formData.password_confirmation"}})],1)],1)],1),_c('api-response',{staticClass:"mt-3",attrs:{"response":_vm.formResponse}}),_c('div',{staticClass:"text-end mt-5"},[_c('v-btn',{attrs:{"rounded":"","color":"primary","width":"200","disabled":!_vm.formValid,"loading":_vm.$store.getters['auth/loading'].changePassword,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("buttons.save"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }